<template>
  <div class="role-user reset-btn-page" v-loading="loading">
    <div class="head">
      <h2 class="title">角色授权列表</h2>
    </div>
    <div class="content">
      <el-form :model="form" :rules="rules" ref="ruleForm" size="medium" class="small-form">
        <el-form-item prop="checkList">
          <div class="check-list">
            <el-checkbox-group v-model="form.checkList">
              <div class="item" v-for="check in checkData" :key="check.id">
                <el-checkbox :label="check.id">{{check.nickname}}</el-checkbox>
              </div>
            </el-checkbox-group>
          </div>
        </el-form-item>
      </el-form>
    </div>
    <FixedActionBar>
      <el-button size="medium" type="primary" :loading="saveLoading" @click="oncConfirm('ruleForm')">提交
      </el-button>
      <el-button size="medium" @click="oncCancel">取消</el-button>
    </FixedActionBar>
  </div>
</template>
<script>
import { roleUsers, roleAuthUsers } from "../api/role";
import FixedActionBar from "@/base/layout/FixedActionBar";
export default {
  components: { FixedActionBar },
  data() {
    return {
      loading: false, //加载中
      form: {
        checkList: [], //选中项id数组
      },
      rules: {},
      checkData: [], //获取到的初始数据
      saveLoading: false, //提交中
    };
  },
  computed: {
    //角色id
    roleId() {
      const id = this.$route.params.id;
      return id ? Number(id) : "";
    },
  },
  methods: {
    //获取角色授权的用户列表
    getRoleUsers() {
      if (!this.roleId) {
        this.$message.warning("没有检测到可授权的角色");
        return;
      }
      this.loading = true;
      roleUsers({ id: this.roleId })
        .then((res) => {
          const { data } = res;
          this.checkData = data;
          //初始化表单
          this.initForm(data);
          this.loading = false;
          // console.log(data);
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    //初始化表单
    initForm(arr) {
      const newArr = [];
      arr.forEach((item) => {
        if (item.checked) newArr.push(item.id);
      });
      this.form.checkList = newArr;
    },
    //提交
    oncConfirm(formName) {
      if (!this.roleId) {
        this.$message.warning("没有检测到可授权的角色");
        return;
      }
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.saveLoading = true;
          //提交的数据
          const postData = {
            id: this.roleId,
            user_ids: [...this.form.checkList],
          };
          //请求
          roleAuthUsers(postData)
            .then((res) => {
              this.$message.success(res.msg);
              this.saveLoading = false;
              //路由跳转
              this.$router.push({ name: "AdminRoleList" });
            })
            .catch((err) => {
              this.saveLoading = false;
            });
        }
      });
    },
    //取消
    oncCancel() {
      this.$router.go(-1);
    },
  },
  created() {
    //获取角色授权的用户列表
    this.getRoleUsers();
  },
};
</script>
<style lang="scss" scoped>
.role-user {
  .head {
    margin-bottom: 32px;
    .title {
      font-size: 18px;
      font-weight: normal;
    }
  }
  .content {
    min-height: 400px;
    .check-list {
      .item {
        max-width: 360px;
        & + .item {
          margin-top: 15px;
        }
        ::v-deep.el-checkbox {
          border: 1px solid rgba(103, 117, 124, 0.15);
          border-radius: 4px;
          padding: 8px 15px;
          display: flex;
          align-items: center;
        }
      }
    }
  }
}
</style>
